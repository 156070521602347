import React, { useContext } from 'react';
import {
  useDelete,
  useTranslate,
  useMutationMode,
  useCan,
  useResource,
  pickNotDeprecated,
  useWarnAboutChange,
  AccessControlContext,
} from '@refinedev/core';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import type { DeleteButtonProps } from '@refinedev/antd';
import { EditRecordAction } from '../../Actions/ConfigsActions';
import { TbTrashOff } from 'react-icons/tb';
import { colors } from '../../Themes/Colors';

/**
 * `<DeleteButton>` uses Ant Design's {@link https://ant.design/components/button/ `<Button>`} and {@link https://ant.design/components/button/ `<Popconfirm>`} components.
 * When you try to delete something, a pop-up shows up and asks for confirmation. When confirmed it executes the `useDelete` method provided by your `dataProvider`.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/buttons/delete-button} for more details.
 */

type CustomDeleteButtonProps = {
  hardDelete?: boolean;
  deleted?: boolean;
  inputs?: any;
  show?: boolean;
  refresh?: () => void;
};
export const CustomDeleteButton: React.FC<
  DeleteButtonProps & CustomDeleteButtonProps
> = ({
  resource: resourceNameFromProps,
  resourceNameOrRouteName: propResourceNameOrRouteName,
  recordItemId,
  onSuccess,
  mutationMode: mutationModeProp,
  // children,
  successNotification,
  errorNotification,
  // hideText = false,
  accessControl,
  metaData,
  meta,
  dataProviderName,
  confirmTitle,
  confirmOkText,
  confirmCancelText,
  invalidates,
  hardDelete,
  inputs,
  deleted,
  show,
  refresh,
  ...rest
}) => {
  const accessControlContext = useContext(AccessControlContext);

  const accessControlEnabled =
    accessControl?.enabled ??
    accessControlContext.options.buttons.enableAccessControl;

  const hideIfUnauthorized =
    accessControl?.hideIfUnauthorized ??
    accessControlContext.options.buttons.hideIfUnauthorized;

  const translate = useTranslate();

  const { id, resource, identifier } = useResource(
    resourceNameFromProps ?? propResourceNameOrRouteName
  );

  const { mutationMode: mutationModeContext } = useMutationMode();

  const mutationMode = mutationModeProp ?? mutationModeContext;

  const { mutate, isLoading, variables } = useDelete();

  const { data } = useCan({
    resource: resource?.name,
    action: 'delete',
    params: { id: recordItemId ?? id, resource },
    queryOptions: {
      enabled: accessControlEnabled,
    },
  });

  const disabledTitle = () => {
    if (data?.can) return '';
    if (data?.reason) data.reason;
    else {
      translate(
        'buttons.notAccessTitle',
        "You don't have permission to access"
      );
    }
  };

  const { setWarnWhen } = useWarnAboutChange();

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <Popconfirm
      key="delete"
      okText={
        confirmOkText ??
        translate('buttons.delete', deleted ? 'Restore' : 'Delete')
      }
      cancelText={confirmCancelText ?? translate('buttons.cancel', 'Cancel')}
      okType={deleted ? 'primary' : 'danger'}
      title={confirmTitle ?? translate('buttons.confirm', 'Are you sure?')}
      okButtonProps={{ disabled: isLoading }}
      onConfirm={(): void => {
        if ((recordItemId ?? id) && identifier) {
          setWarnWhen(false);

          if (hardDelete) {
            mutate(
              {
                id: recordItemId ?? id ?? '',
                resource: identifier,
                mutationMode,
                successNotification,
                errorNotification,
                meta: pickNotDeprecated(meta, metaData),
                metaData: pickNotDeprecated(meta, metaData),
                dataProviderName,
                invalidates,
              },
              {
                onSuccess: (value) => {
                  onSuccess && onSuccess(value);
                },
              }
            );
          } else {
            EditRecordAction(
              identifier,
              recordItemId ?? id ?? '',
              inputs || { is_deleted: true },
              refresh
            );
          }
        }
      }}
      disabled={
        typeof rest?.disabled !== 'undefined'
          ? rest.disabled
          : data?.can === false
      }
    >
      <Button
        danger={!deleted}
        style={deleted && !show ? { marginTop: 3 } : {}}
        loading={(recordItemId ?? id) === variables?.id && isLoading}
        icon={
          deleted ? (
            <TbTrashOff
              size={17}
              color={colors.green}
              style={{ marginLeft: 1, marginTop: 3 }}
            />
          ) : (
            <DeleteOutlined />
          )
        }
        title={disabledTitle()}
        disabled={data?.can === false}
        // className={RefineButtonClassNames.DeleteButton}
        {...rest}
      >
        {/* {!hideText &&
          (children ??
            translate('buttons.delete', deleted ? 'Restore' : 'Delete'))} */}
      </Button>
    </Popconfirm>
  );
};
