import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  TagField,
  CreateButton,
  FilterDropdown,
} from '@refinedev/antd';
import { Table, Space, Select } from 'antd';
import { Search } from '../../components/Search';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export const ProductList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });
  // const [modalOpen, setModalOpen] = useState(false);

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;

  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="product" />,
        <CreateButton key="create" />,
      ]}
    >
      {/* <SecurityQuestionModal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
      /> */}

      {/* <AwaitsCashBackModal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
      /> */}
      <Search
        path="product"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => setIsEmptySearch(false)}
      />
      <Table {...tableProps} rowKey="id" pagination={paginationConfig}>
        <Table.Column
          dataIndex="type"
          title="Type"
          render={(value: any[]) => (
            <>
              {value?.map((item, index) => (
                <TagField key={index} value={item?.type?.name} />
              ))}
            </>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="multiple"
                placeholder="Select Type"
                options={Array.from(
                  new Set(
                    tableProps?.dataSource?.flatMap((item) =>
                      item?.type?.map((type: any) => type?.type?.name)
                    )
                  )
                ).map((name) => ({
                  label: name,
                  value: name,
                }))}
                style={{ minWidth: '200px', maxWidth: '400px' }}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="tier"
          title="Tier"
          render={(value: any[]) => (
            <>
              {value?.map((item, index) => (
                <TagField key={index} value={item?.tier?.name} />
              ))}
            </>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="multiple"
                placeholder="Select Tier"
                options={Array.from(
                  new Set(
                    tableProps?.dataSource?.flatMap((item) =>
                      item?.tier?.map((tier: any) => tier?.tier?.name)
                    )
                  )
                ).map((name) => ({
                  label: name,
                  value: name,
                }))}
                style={{ minWidth: '200px', maxWidth: '400px' }}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="segment"
          title="Segment"
          render={(value: any[]) => (
            <>
              {value?.map((item, index) => (
                <TagField key={index} value={item?.segment?.name} />
              ))}
            </>
          )}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                mode="multiple"
                placeholder="Select Segment"
                options={Array.from(
                  new Set(
                    tableProps?.dataSource?.flatMap((item) =>
                      item?.segment?.map(
                        (segment: any) => segment?.segment?.name
                      )
                    )
                  )
                ).map((name) => ({
                  label: name,
                  value: name,
                }))}
                style={{ minWidth: '200px', maxWidth: '400px' }}
              />
            </FilterDropdown>
          )}
        />

        <Table.Column
          dataIndex="is_deleted"
          title="Status"
          render={(value: any) => (
            <Space>
              {value ? (
                <CloseCircleOutlined style={{ color: 'red' }} />
              ) : (
                <CheckCircleOutlined style={{ color: 'green' }} />
              )}
              {value ? 'Inactive' : 'Active'}
            </Space>
          )}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              {record.is_deleted ? (
                <>
                  <EditButton hideText size="small" disabled />
                  <DeleteButton hideText size="small" disabled />
                </>
              ) : (
                <>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  <DeleteButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                </>
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
