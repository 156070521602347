import React, { useState } from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DeleteButton,
  DateField,
  CreateButton,
  ShowButton,
} from '@refinedev/antd';
import { Table, Space } from 'antd';
import { Search } from '../../components/Search';
import { ExportCsvButton } from '../../components/buttons/CustomExportCsvButton';

export const SegmentList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any>([]);

  const handleEmptySearch = () => {
    setIsEmptySearch(true);
  };

  if (searchResults?.length > 0) {
    tableProps.dataSource = searchResults;
  } else if (isEmptySearch) {
    tableProps.dataSource = [];
  }

  const paginationConfig =
    tableProps.dataSource !== undefined && searchResults.length > 0
      ? {
          total: searchResults.length > 0 ? searchResults.length : undefined,
        }
      : undefined;
  return (
    <List
      headerButtons={[
        <ExportCsvButton key="export" path="segment" />,
        <CreateButton key="create" />,
      ]}
    >
      <Search
        path="segment"
        setSearchResults={setSearchResults}
        searchResults={searchResults}
        onEmptySearch={handleEmptySearch}
        onCancelSearch={() => setIsEmptySearch(false)}
      />

      <Table {...tableProps} rowKey="id" pagination={paginationConfig}>
        <Table.Column dataIndex="name" title="Name" />
        <Table.Column
          dataIndex={['created_at']}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column dataIndex={['created_by', 'name']} title="Created By" />

        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              {record.is_deleted ? (
                <DeleteButton hideText size="small" disabled />
              ) : (
                <DeleteButton hideText size="small" recordItemId={record.id} />
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
