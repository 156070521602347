import React, { useContext } from 'react';
import { styled } from 'styled-components';
import packageJson from '../../../package.json';
import { ColorModeContext } from '../../contexts/color-mode';

export default function Footer() {
  const { mode } = useContext(ColorModeContext);

  return (
    <div>
      <Text isDark={mode === 'dark'}>Version: {packageJson.version}</Text>
    </div>
  );
}

const Text = styled.div<{ isDark: boolean }>`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: ${({ isDark }) => (isDark ? '#fff' : '#000')};
  margin-bottom: 0;
  text-align: center;
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
`;
