/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Input } from 'antd';
import { SearchAction } from '../../Actions/ConfigsActions';

interface Props {
  path: string;
  setSearchResults: (val: any) => void;
  searchResults: any[];
  onEmptySearch: () => void;
  onCancelSearch: () => void;
}

export const Search = ({
  path,
  setSearchResults,
  searchResults,
  onEmptySearch,
  onCancelSearch,
}: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const [isEmptySearch, setIsEmptySearch] = useState<boolean>(false);

  const handleSearch = (value: string) => {
    SearchAction(`${path}/search?search=${value}`, (results) => {
      setSearchResults(results);

      if (results.length === 0) {
        setIsEmptySearch(true);
        onEmptySearch();
      } else {
        setIsEmptySearch(false);
      }
    });
  };

  const resetData = () => {
    setSearchResults([]);
  };

  return (
    <>
      <Input.Group compact>
        <Input.Search
          placeholder={'Search...'}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={(value) => {
            if (value.trim() === '') {
              onEmptySearch();
              resetData();
              onCancelSearch();
            } else {
              handleSearch(value);
            }
          }}
          allowClear
          style={{ width: 300, paddingBottom: '1rem' }}
        />
      </Input.Group>
    </>
  );
};
