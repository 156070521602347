import React from "react";
import { IResourceComponentsProps, useShow } from "@refinedev/core";
import {
    Show,
    NumberField,
    TextField,
    BooleanField,
} from "@refinedev/antd";
import { Typography } from "antd";
const { Title } = Typography;
export const TypeShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const record = data?.data;
    return (
        <Show isLoading={isLoading}>
            <Title level={5}>Id</Title>
            <NumberField value={record?.id ?? ""} />
            <Title level={5}>Name</Title>
            <TextField value={record?.name} />
            <Title level={5}>Is Deleted</Title>
            <BooleanField value={record?.is_deleted} />
            <Title level={5}>Created By</Title>
            <TextField value={record?.created_by?.name} />
        </Show>
    );
};