import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import {
  NumberField,
  DateField,
  BooleanField,
  TextField,
  EmailField,
} from '@refinedev/antd';
import { Typography } from 'antd';
import { Show } from '../../components/crud/show';

const { Title } = Typography;

export const AdminShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow({
    resource: 'admin',
  });
  const { data, isLoading, refetch } = queryResult;

  const record = data?.data;

  return (
    <Show
      isLoading={isLoading}
      deleteProps={{
        inputs: { is_deleted: !record?.is_deleted },
        deleted: record?.is_deleted,
        refresh: refetch,
      }}
      customEditID={record?.account_id}
    >
      <Title level={5}>Id</Title>
      <NumberField value={record?.id ?? ''} />
      <Title level={5}>Email</Title>
      <EmailField value={record?.account?.email} />
      <Title level={5}>First Name</Title>
      <TextField value={record?.account?.first_name} />
      <Title level={5}>Last Name</Title>
      <NumberField value={record?.account?.last_name ?? ''} />
      <Title level={5}>Cognito Username</Title>
      <TextField value={record?.account?.cognito_username} />
      <Title level={5}>Account Type</Title>
      <TextField value={record?.account?.account_type} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
      <Title level={5}>Deleted</Title>
      <BooleanField value={record?.is_deleted} />
    </Show>
  );
};
