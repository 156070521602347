export const colors = {
  lightBlue: '#b3cbdd',
  blue: '#1e91cf',
  blue2: '#197bb0',
  gray: '#7f8a9b',
  green: '#4cb64c',
  green2: '#409e40',
  red: '#e3503e',
  red2: '#dd3520',
  mdSemiDefultBoldColor: '#344054',
  logOutIconColor: '#475467',
};
