import * as React from 'react';
import { SVGProps } from 'react';
const ImportIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    {...props}
  >
    <path
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.667}
      d="M13.333 10 10 6.667m0 0L6.667 10M10 6.667v7.667c0 1.159 0 1.738.459 2.387.305.43 1.182.963 1.705 1.034.788.107 1.087-.05 1.685-.361a8.333 8.333 0 1 0-8.015-.175"
    />
  </svg>
);
export default ImportIcon;
