import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Show, TextField, DateField } from '@refinedev/antd';
import { Tag, Typography } from 'antd';

const { Title } = Typography;

export const EarningMetricsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();

  useShow({
    resource: 'earning-metrics',
  });
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Name</Title>
      <TextField value={record?.name} />
      <Title level={5}>Start Date</Title>
      {record?.start_date && <DateField value={record?.start_date} />}
      <Title level={5}>End Date</Title>
      {record?.end_date && <DateField value={record?.end_date} />}
      <Title level={5}>Creation Date</Title>
      <DateField value={record?.created_at} />
      <Title level={5}>Status</Title>
      <Typography.Text>
        {record?.is_deleted ? (
          <Tag color="red">Inactive</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        )}
      </Typography.Text>
    </Show>
  );
};
