import React from 'react';
import { NotificationProvider } from '@refinedev/core';
import { toast } from 'react-toastify';
import { UndoableNotification } from '../components/undoableNotification';
import { notificationFilter } from '../utils/notification-types-filter.helper';

export const notificationProvider: NotificationProvider = {
  open: ({ key, message, type, undoableTimeout, cancelMutation }) => {
    const specificKey = key?.includes('type')
      ? 'type'
      : key?.includes('tier')
        ? 'tier'
        : key?.includes('segment')
          ? 'segment'
          : key?.includes('product')
            ? 'product'
            : key?.includes('earning-metric')
              ? 'earning_metric'
              : key?.includes('calculation-module-admin')
                ? 'calculation_module_admin'
                : undefined;

    const filteredMessage = notificationFilter(
      specificKey as
        | 'type'
        | 'tier'
        | 'segment'
        | 'product'
        | 'earning_metric'
        | 'calculation_module_admin'
        | undefined,
      type,
      message
    );

    if (type === 'progress') {
      if (toast.isActive(key as React.ReactText)) {
        toast.update(key as React.ReactText, {
          progress: undoableTimeout && (undoableTimeout / 10) * 2,
          render: (
            <UndoableNotification
              message={filteredMessage}
              cancelMutation={cancelMutation}
            />
          ),
          type: 'default',
        });
      } else {
        toast(
          <UndoableNotification
            message={filteredMessage}
            cancelMutation={cancelMutation}
          />,
          {
            toastId: key,
            updateId: key,
            closeOnClick: false,
            closeButton: false,
            autoClose: false,
            progress: undoableTimeout && (undoableTimeout / 10) * 2,
          }
        );
      }
    } else {
      if (toast.isActive(key as React.ReactText)) {
        toast.update(key as React.ReactText, {
          render: filteredMessage,
          closeButton: true,
          autoClose: 5000,
          type,
        });
      } else {
        toast(filteredMessage, {
          toastId: key,
          type,
        });
      }
    }
  },
  close: (key) => toast.dismiss(key),
};
