import React from 'react';
import { Authenticated, ErrorComponent } from '@refinedev/core';
import {
  CatchAllNavigate,
  NavigateToResource,
} from '@refinedev/react-router-v6';
import Footer from '../components/Footer';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Header } from '../components/header';
import { AdminList } from '../pages/admin/list';
import { AntdInferencer } from '@refinedev/inferencer/antd';
import { AdminCreate, AdminEdit, AdminShow } from '../pages/admin';
import { Login } from '../Auth/Login';
import * as Sentry from '@sentry/react';
import { ForgotPassword } from '../Auth/ForgotPassword';
import { ResetPassword } from '../Auth/ResetPassword';
import { ThemedLayoutV2 } from '../components/layout';
import { ThemedSiderV2 } from '../components/layout/sider';
import { ThemedTitleV2 } from '../components/layout/title';
import { Verification } from '../Auth/Verification';
import { TypeCreate, TypeEdit, TypeList, TypeShow } from '../pages/type';
import { TierCreate, TierEdit, TierList } from '../pages/tier';
import { SegmentCreate, SegmentEdit, SegmentList } from '../pages/segment';
import { ProductCreate, ProductEdit, ProductList } from '../pages/product';
import {
  EarningMetricCreate,
  EarningMetricEdit,
  EarningMetricList,
  EarningMetricsShow,
} from '../pages/earning-metrics';
import {
  DashBoardAdminCreate,
  DashBoardAdminEdit,
  DashBoardAdminList,
  DashBoardAdminShow,
} from '../pages/calculationAdmin';
import { CustomersList, CustomersShow } from '../pages/customers';
const Stacks = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <Routes>
      <Route
        element={
          <Authenticated
            key="dashboard"
            fallback={<CatchAllNavigate to="/login" />}
          >
            <ThemedLayoutV2
              Header={Header}
              Footer={Footer}
              Title={ThemedTitleV2}
              Sider={ThemedSiderV2}
            >
              <Outlet />
            </ThemedLayoutV2>
          </Authenticated>
        }
      >
        <Route index element={<NavigateToResource resource="product" />} />
        <Route path="/admin">
          <Route index element={<AdminList />} />
          <Route path="create" element={<AdminCreate />} />
          <Route path="edit/:id" element={<AdminEdit />} />
          <Route path="show/:id" element={<AdminShow />} />
        </Route>
        <Route path="/account">
          <Route index element={<AntdInferencer />} />
          <Route path="create" element={<AntdInferencer />} />
          <Route path="edit/:id" element={<AntdInferencer />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/dashboard">
          <Route index element={<AntdInferencer />} />
          <Route path="create" element={<AntdInferencer />} />
          <Route path="edit/:id" element={<AntdInferencer />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/earning-metric">
          <Route index element={<EarningMetricList />} />
          <Route path="create" element={<EarningMetricCreate />} />
          <Route path="edit/:id" element={<EarningMetricEdit />} />
          <Route path="show/:id" element={<EarningMetricsShow />} />
        </Route>
        <Route path="/product">
          <Route index element={<ProductList />} />
          <Route path="create" element={<ProductCreate />} />
          <Route path="edit/:id" element={<ProductEdit />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/type">
          <Route index element={<TypeList />} />
          <Route path="create" element={<TypeCreate />} />
          <Route path="edit/:id" element={<TypeEdit />} />
          <Route path="show/:id" element={<TypeShow />} />
        </Route>
        <Route path="/tier">
          <Route index element={<TierList />} />
          <Route path="create" element={<TierCreate />} />
          <Route path="edit/:id" element={<TierEdit />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/segment">
          <Route index element={<SegmentList />} />
          <Route path="create" element={<SegmentCreate />} />
          <Route path="edit/:id" element={<SegmentEdit />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/report">
          <Route index element={<AntdInferencer />} />
          <Route path="create" element={<AntdInferencer />} />
          <Route path="edit/:id" element={<AntdInferencer />} />
          <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="/calculation-module-admin">
          <Route index element={<DashBoardAdminList />} />
          <Route path="create" element={<DashBoardAdminCreate />} />
          <Route path="edit/:id" element={<DashBoardAdminEdit />} />
          <Route path="show/:id" element={<DashBoardAdminShow />} />
        </Route>
        <Route path="/customers">
          <Route index element={<CustomersList />} />
          <Route path="show/:id" element={<CustomersShow />} />
          {/* <Route path="customer/:id" element={<DetailsCustomer />} /> */}
        </Route>
        <Route path="*" element={<ErrorComponent />} />
      </Route>
      <Route
        element={
          <Authenticated key="dashboard" fallback={<Outlet />}>
            <NavigateToResource />
          </Authenticated>
        }
      >
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verification" element={<Verification />} />
      </Route>
    </Routes>
  );
};

export default Stacks;
