import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { exportCsvAction } from '../../Actions/ConfigsActions';

interface Props {
  path: string;
}

const handleExportation = (path: string) => {
  exportCsvAction(`${path}/excel-file`);
};

export const ExportCsvButton = ({ path }: Props) => {
  return (
    <Button
      type="primary"
      icon={<CloudDownloadOutlined />}
      onClick={() => handleExportation(path)}
    >
      Download CSV
    </Button>
  );
};
