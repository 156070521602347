import { DevtoolsPanel, DevtoolsProvider } from '@refinedev/devtools';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import '@refinedev/antd/dist/reset.css';
import routerBindings, {
  DocumentTitleHandler,
  UnsavedChangesNotifier,
} from '@refinedev/react-router-v6';
// import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { authProvider } from './providers/authProvider';
import { ColorModeContextProvider } from './contexts/color-mode';
// import { ConfigsStore } from './Store';
import { useEffect } from 'react';
import { axiosInstance } from './Actions/AuthActions';
import { customDataProvider } from './providers/rest-data-provider/index';
import { notificationProvider } from './providers/notificationProvider';
import { API_URL } from './configs';
import { AxiosInstance } from 'axios';
import Stacks from './Stacks';
import { ToastContainer } from 'react-toastify';
import { Refine } from '@refinedev/core';
import { CreateDeviceAction } from './Actions/ConfigsActions';
import {
  CreditCardOutlined,
  SwapOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Params } from './types';

const customTitleHandler = ({ resource, action }: Params) => {
  let title = 'Calculation Module ';

  if (resource && action) {
    title = `${resource.meta?.label} ${action} `;
  }

  return title;
};

function App() {
  // const [loggedIn, setLoggedIn] = useState<boolean>();
  // const [rememberMe, setRememberMe] = useState<boolean>();
  const getCookie = async () => {
    // setLoggedIn(await ConfigsStore.get('logged_in'));
    // setRememberMe(await ConfigsStore.get('rememberMe'));
  };
  useEffect(() => {
    CreateDeviceAction();
    getCookie();
  }, []);
  // const { t, i18n } = useTranslation();
  // const i18nProvider = {
  //   translate: (key: string, params: object) => t(key, params),
  //   changeLocale: (lang: string) => i18n.changeLanguage(lang),
  //   getLocale: () => i18n.language,
  // };

  return (
    <DevtoolsProvider>
      <BrowserRouter>
        <RefineKbarProvider>
          <ColorModeContextProvider>
            <Refine
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              resources={[
                // {
                //   name: 'admin',
                //   list: '/admin',
                //   create: '/admin/create',
                //   edit: '/admin/edit/:id',
                //   show: '/admin/show/:id',
                //   meta: {
                //     canDelete: true,
                //   },
                //   icon: icons.rotateLeft,
                // },
                // {
                //   name: 'account',
                //   list: '/account',
                //   create: '/account/create',
                //   edit: '/account/edit/:id',
                //   show: '/account/show/:id',
                //   meta: {
                //     canDelete: true,
                //   },
                // },
                // {
                //   name: 'Dashboard',
                //   list: '/dashboard',
                //   options: { label: 'Dashboard' },
                //   icon: (
                //     <img src={sideIcons.dashboardIcon} alt="Dashboard Icon" />
                //   ),
                // },
                {
                  name: 'earning-metric',
                  list: '/earning-metric',
                  create: '/earning-metric/create',
                  edit: '/earning-metric/edit/:id',
                  show: '/earning-metric/show/:id',
                  meta: {
                    canDelete: false,
                    label: 'Earning Metric',
                  },
                  icon: <SwapOutlined />,
                },
                {
                  name: 'product',
                  list: '/product',
                  create: '/product/create',
                  edit: '/product/edit/:id',
                  show: '/product/show/:id',
                  meta: {
                    canDelete: false,
                    label: 'Products',
                  },
                  icon: <CreditCardOutlined />,
                },
                {
                  name: 'type',
                  list: '/type',
                  create: '/type/create',
                  edit: '/type/edit/:id',
                  show: '/type/show/:id',
                  meta: {
                    canDelete: false,
                    // parent: 'product',
                    label: 'Types',
                  },
                },
                {
                  name: 'tier',
                  list: '/tier',
                  create: '/tier/create',
                  edit: '/tier/edit/:id',
                  show: '/tier/show/:id',
                  meta: {
                    canDelete: false,
                    // parent: 'product',
                    label: 'Tiers',
                  },
                },
                {
                  name: 'segment',
                  list: '/segment',
                  create: '/segment/create',
                  edit: '/segment/edit/:id',
                  show: '/segment/show/:id',
                  meta: {
                    canDelete: false,
                    // parent: 'product',
                    label: 'Segments',
                  },
                },
                {
                  name: 'account-cruds/financial-data',
                  list: '/customers',
                  create: '/customers/create',
                  edit: '/customers/edit/:id',
                  show: '/customers/show/:id',
                  meta: {
                    canDelete: true,
                    label: 'Customers',
                    enabled: true,
                  },
                  icon: (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22 21V19C22 17.1362 20.7252 15.5701 19 15.126M15.5 3.29076C16.9659 3.88415 18 5.32131 18 7C18 8.67869 16.9659 10.1159 15.5 10.7092M17 21C17 19.1362 17 18.2044 16.6955 17.4693C16.2895 16.4892 15.5108 15.7105 14.5307 15.3045C13.7956 15 12.8638 15 11 15H8C6.13623 15 5.20435 15 4.46927 15.3045C3.48915 15.7105 2.71046 16.4892 2.30448 17.4693C2 18.2044 2 19.1362 2 21M13.5 7C13.5 9.20914 11.7091 11 9.5 11C7.29086 11 5.5 9.20914 5.5 7C5.5 4.79086 7.29086 3 9.5 3C11.7091 3 13.5 4.79086 13.5 7Z"
                        stroke="#667085"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ),
                },
                // {
                //   name: 'campaign',
                //   list: '/campaign',
                //   create: '/campaign/create',
                //   edit: '/campaign/edit/:id',
                //   show: '/campaign/show/:id',
                //   meta: {
                //     canDelete: true,
                //   },
                //   icon: (
                //     <img src={sideIcons.campaignsIcon} alt="Campaigns Icon" />
                //   ),
                // },
                // {
                //   name: 'report',
                //   list: '/report',
                //   create: '/report/create',
                //   edit: '/report/edit/:id',
                //   show: '/report/show/:id',
                //   meta: {
                //     canDelete: true,
                //   },
                //   icon: <img src={sideIcons.reportsIcon} alt="Reports Icon" />,
                // },
                {
                  name: 'calculation-module-admin',
                  list: '/calculation-module-admin',
                  create: '/calculation-module-admin/create',
                  edit: '/calculation-module-admin/edit/:id',
                  show: '/calculation-module-admin/show/:id',
                  meta: {
                    canDelete: true,
                    label: 'Dashboard Admin',
                  },
                  icon: <UserOutlined />,
                },
              ]}
              dataProvider={customDataProvider(
                API_URL,
                axiosInstance as AxiosInstance
              )}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: false,
              }}
            >
              <Stacks /> {/* add the routes here  */}
              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={customTitleHandler} />
              <ToastContainer />
            </Refine>
            <DevtoolsPanel />
          </ColorModeContextProvider>
        </RefineKbarProvider>
      </BrowserRouter>
    </DevtoolsProvider>
  );
}

export default App;
