import React from 'react';
import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form } from 'antd';
import { useFormik } from 'formik';
import { CreateRecordAction } from '../../Actions/ConfigsActions';
import { AccountTypeEnum } from '../../types';
import * as Yup from 'yup';
import { CustomInput } from '../../components/CustomInput';

export const AdminCreate: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();

  const { formProps, saveButtonProps } = useForm({
    resource: 'admin',
    submitOnEnter: true,
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    username: Yup.string().required('Username is required'),
    phone_number: Yup.string().required('Phone Number is required'),
    password: Yup.string().required('Password is required'),
  });

  const { errors, touched, handleSubmit, handleChange } = useFormik({
    initialValues: {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      cognito_username: 'asxa',
      cognito_user_id: 'xcxc',
      username: '',
      phone_number: '',
      password: '',
    },
    validationSchema,
    onSubmit: async (submittedValues) => {
      const inputs: any = {
        username: submittedValues.username,
        phone_number: submittedValues.phone_number,
        first_name: submittedValues.first_name,
        last_name: submittedValues.last_name,
        email: submittedValues.email,
        cognito_username: submittedValues.cognito_username,
        cognito_user_id: submittedValues.cognito_user_id,
        account_type: AccountTypeEnum.admin,
        password: submittedValues.password,
      };

      if (Object.keys(inputs)?.length > 0) {
        CreateRecordAction('account', inputs, undefined, goBack);
      }
    },
  });

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <CustomInput
          label={'Username'}
          name={'username'}
          onChange={handleChange}
          error={!!(errors.username && touched.username)}
          errorMessage={errors.username}
          required
        />

        <CustomInput
          label={'First Name'}
          name={'first_name'}
          onChange={handleChange}
          error={!!(errors.first_name && touched.first_name)}
          errorMessage={errors.first_name}
          required
        />

        <CustomInput
          label={'Last Name'}
          name={'last_name'}
          onChange={handleChange}
          error={!!(errors.last_name && touched.last_name)}
          errorMessage={errors.last_name}
          required
        />

        <CustomInput
          label={'Email'}
          name={'email'}
          onChange={handleChange}
          type="email"
          error={!!(errors.email && touched.email)}
          errorMessage={errors.email}
          required
        />

        <CustomInput
          label={'Phone Number'}
          name={'phone_number'}
          type="tel"
          onChange={handleChange}
          error={!!(errors.phone_number && touched.phone_number)}
          errorMessage={errors.phone_number}
          required
        />
        <CustomInput
          required
          label={'Password'}
          name={'password'}
          type="password"
          onChange={handleChange}
          error={!!(errors.password && touched.password)}
          errorMessage={errors.password}
        />
      </Form>
    </Create>
  );
};
