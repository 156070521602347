import React, { useState } from 'react';
import '../ForgotPassword/styles.css';
import colors from '../../Theme/colors';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useNavigation } from '@refinedev/core';
import logo from '../../Assests/loynovaLogo.svg';
import { ResetPasswordAction } from '../../Actions/AuthActions';
import { PasswordInput } from '../../components/passwordInput';

export const ResetPassword: React.FC = () => {
  const [disabled, setDisabled] = useState(false);
  const { replace } = useNavigation();
  const CardTitle = (
    <div className="left-layout-title">
      Reset password
      <div className="left-layout-sub-title">
        Set a new password for your account so that you can login.
      </div>
    </div>
  );
  const navigateToLogIn = () => {
    replace('/login');
  };

  const handleDisableButton = (value: boolean) => {
    setDisabled(value);
  };

  return (
    <div
      style={{
        background: `#f5f5f5`,
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="370"
              alt="logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form
                layout="vertical"
                color={colors.primaryLight}
                onFinish={(values) => {
                  ResetPasswordAction(values.password, navigateToLogIn);
                }}
                requiredMark={false}
              >
                <PasswordInput />

                <Form.Item
                  name="confirmPassword"
                  label={'Confirm password'}
                  dependencies={['password']}
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password',
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-/|])[A-Za-z\d!@#$%^&*()_+-/|]/,
                      message:
                        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
                    },
                    {
                      min: 8,
                      message: 'Password must be at least 8 characters',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          handleDisableButton(false);
                          return Promise.resolve();
                        }
                        handleDisableButton(true);
                        return Promise.reject(
                          new Error('Passwords do not match')
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="password" size="large" placeholder="••••••••" />
                </Form.Item>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  style={{
                    background: colors.authButtonColor,
                  }}
                  disabled={disabled}
                >
                  <p className="auth-button-text">{'Update'}</p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
