import i18n from '../i18n';

export const notificationFilter = (
  key:
    | 'type'
    | 'tier'
    | 'segment'
    | 'product'
    | 'earning_metric'
    | 'calculation_module_admin'
    | undefined,
  type: 'success' | 'error' | 'progress',
  originalMessage: string
) => {
  let message = originalMessage;

  if (type !== 'error' || key === undefined) return message;

  if (message?.includes('creating')) {
    const errorMessages = {
      type: 'notifications.customCreateTypeErrorMessage',
      tier: 'notifications.customCreateTierErrorMessage',
      segment: 'notifications.customCreateSegmentErrorMessage',
      product: 'notifications.customCreateProductErrorMessage',
      earning_metric: 'notifications.customCreateEarningMetricErrorMessage',
      calculation_module_admin: 'notifications.customCreateAdminsErrorMessage',
    };

    const errorMessageKey = errorMessages[key];

    if (errorMessageKey) {
      message = i18n.t(errorMessageKey);
    }
  }

  if (message?.includes('updating')) {
    const errorMessages = {
      type: 'notifications.customUpdateSegmentErrorMessage',
      tier: 'notifications.customUpdateSegmentErrorMessage',
      segment: 'notifications.customUpdateSegmentErrorMessage',
      product: 'notifications.customUpdateProductErrorMessage',
      earning_metric: 'notifications.customUpdateEarningMetricErrorMessage',
      calculation_module_admin: 'notifications.customUpdateAdminsErrorMessage',
    };

    const errorMessageKey = errorMessages[key];
    if (errorMessageKey) {
      message = i18n.t(errorMessageKey);
    }
  } else if (message?.includes('Error')) {
    const errorMessages = {
      type: 'notifications.customDeleteTypeErrorMessage',
      tier: 'notifications.customDeleteTierErrorMessage',
      segment: 'notifications.customDeleteSegmentErrorMessage',
      product: 'notifications.customDeleteProductErrorMessage',
      earning_metric: 'notifications.customDeleteEarningMetricErrorMessage',
      calculation_module_admin: 'notifications.customDeleteAdminsErrorMessage',
    };

    const errorMessageKey = errorMessages[key];
    if (errorMessageKey) {
      message = i18n.t(errorMessageKey);
    }
  }

  return message;
};
