import React, { useContext } from 'react';
import {
  useCan,
  useNavigation,
  useTranslate,
  useResource,
  useRouterContext,
  useRouterType,
  useLink,
  AccessControlContext,
} from '@refinedev/core';
import { EyeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ShowButtonProps } from '@refinedev/antd';

/**
 * `<ShowButton>` uses Ant Design's {@link https://ant.design/components/button/ `<Button>`} component.
 * It uses the {@link https://refine.dev/docs/api-reference/core/hooks/navigation/useNavigation#show `show`} method from {@link https://refine.dev/docs/api-reference/core/hooks/navigation/useNavigation `useNavigation`} under the hood.
 * It can be useful when redirecting the app to the show page with the record id route of resource.
 *
 * @see {@link https://refine.dev/docs/api-reference/antd/components/buttons/show-button} for more details.
 */

type CustomShowButtonProps = {
  goTo?: string;
};

export const CustomShowButton: React.FC<
  ShowButtonProps & CustomShowButtonProps
> = ({
  resource: resourceNameFromProps,
  resourceNameOrRouteName: propResourceNameOrRouteName,
  recordItemId,
  hideText = false,
  accessControl,
  meta,
  children,
  goTo,
  onClick,
  ...rest
}) => {
  const accessControlContext = useContext(AccessControlContext);

  const accessControlEnabled =
    accessControl?.enabled ??
    accessControlContext.options.buttons.enableAccessControl;

  const hideIfUnauthorized =
    accessControl?.hideIfUnauthorized ??
    accessControlContext.options.buttons.hideIfUnauthorized;

  const { showUrl: generateShowUrl } = useNavigation();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();
  const { show } = useNavigation();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  const translate = useTranslate();

  const { id, resource } = useResource(
    resourceNameFromProps ?? propResourceNameOrRouteName
  );

  const { data } = useCan({
    resource: resource?.name,
    action: 'show',
    params: { id: recordItemId ?? id, resource },
    queryOptions: {
      enabled: accessControlEnabled,
    },
  });

  const createButtonDisabledTitle = () => {
    if (data?.can) return '';
    if (data?.reason) data.reason;
    else {
      translate(
        'buttons.notAccessTitle',
        "You don't have permission to access"
      );
    }
  };

  const showUrl =
    resource && (recordItemId || id)
      ? generateShowUrl(resource, recordItemId! ?? id!, meta)
      : '';

  if (accessControlEnabled && hideIfUnauthorized && !data?.can) {
    return null;
  }

  return (
    <ActiveLink
      to={showUrl}
      replace={false}
      onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
        if (data?.can === false) {
          e.preventDefault();
          return;
        }
        if (onClick) {
          e.preventDefault();
          if (goTo) {
            recordItemId && show(goTo, recordItemId);
            return;
          }
          onClick(e);
        }
      }}
    >
      <Button
        icon={<EyeOutlined />}
        disabled={data?.can === false}
        title={createButtonDisabledTitle()}
        {...rest}
      >
        {!hideText && (children ?? translate('buttons.show', 'Show'))}
      </Button>
    </ActiveLink>
  );
};
