import React from 'react';
import { IResourceComponentsProps, BaseRecord } from '@refinedev/core';
import {
  useTable,
  List,
  EditButton,
  DateField,
  BooleanField,
  EmailField,
} from '@refinedev/antd';
import { Table, Space } from 'antd';
import { CustomDeleteButton } from '../../components/buttons/CustomDeleteButton';
import { CustomShowButton } from '../../components/buttons/CustomShowButton';

export const AdminList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable({
    syncWithLocation: true,
  });

  // const { data: accountData, isLoading: accountIsLoading } = useMany({
  //   resource: 'account',
  //   ids: tableProps?.dataSource?.map((item) => item?.account_id) ?? [],
  //   queryOptions: {
  //     enabled: !!tableProps?.dataSource,
  //   },
  // });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex={['account_id']} title="id" />
        <Table.Column
          dataIndex={['account', 'email']}
          title="Email"
          render={(value: any) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex={['account', 'first_name']}
          title="First Name"
        />
        <Table.Column dataIndex={['account', 'last_name']} title="Last Name" />
        <Table.Column
          dataIndex={['account', 'cognito_username']}
          title="Cognito Username"
        />
        <Table.Column
          dataIndex={['created_at']}
          title="Created At"
          render={(value: any) => <DateField value={value} />}
        />
        <Table.Column
          dataIndex={['is_deleted']}
          title="Deleted"
          align="center"
          render={(value: any) => <BooleanField value={value} />}
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <CustomShowButton
                hideText
                size="small"
                recordItemId={record?.account_id}
              />
              <CustomDeleteButton
                hideText
                size="small"
                inputs={{ is_deleted: !record.is_deleted }}
                deleted={record.is_deleted}
                refresh={tableQueryResult?.refetch}
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
