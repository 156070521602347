import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';

export const TypeCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={'name'}
          rules={[
            {
              required: true,
              message: 'Please enter a valid product type name.',
            },

            {
              min: 3,
              message: 'The type name must be at least [3 characters] long.',
            },
            {
              max: 30,
              message: 'The type name cannot be longer than [30 characters].',
            },
            {
              type: 'string',
              whitespace: true,
            },
            {
              pattern: /^[a-zA-Z]+$/,
              message: 'Please enter a valid product type name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
