import React from 'react';
import { Form, Input } from 'antd';
import { styled } from 'styled-components';

type CustomInputProps = {
  label: string;
  name: string;
  type?: 'email' | 'password' | 'text' | 'number' | 'tel' | 'date';
  onChange: (e: any) => void;
  value?: string;
  error?: boolean;
  required?: boolean;
  errorMessage?: string;
  rules?: any[];
};

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  name,
  onChange,
  error,
  errorMessage,
  type,
  rules,
  value,
  required,
}) => {
  return (
    <Form.Item label={label} name={[name]} rules={rules} required={required}>
      <Input name={name} value={value} onChange={onChange} type={type} />
      {error && <ErrorText style={{ color: 'red' }}>{errorMessage}</ErrorText>}
    </Form.Item>
  );
};

const ErrorText = styled.div`
  color: red;
  font-size: 14px;
  margin-bottom: -12px;
`;
