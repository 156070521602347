import React, { useState } from 'react';
import logo from '../Assests/loynovaLogo.svg';
import './styles.css';
import colors from '../Theme/colors';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import { useLogin, useNavigation } from '@refinedev/core';
import { ConfigsStore } from '../Store';
export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}
export const Login = () => {
  const [form] = Form.useForm<ILoginForm>();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const { mutate: login } = useLogin<ILoginForm>();
  const { push } = useNavigation();
  const navigateToForgotPassword = () => {
    push('/forgot-password');
  };
  const CardTitle = (
    <div className="layout-title">
      {'Welcome back'}
      <div className="layout-sub-title">
        Welcome back! Please enter your details.
      </div>
    </div>
  );
  return (
    <div
      style={{
        background: '#f5f5f5',
        backgroundSize: 'cover',
      }}
    >
      <Row
        justify="center"
        align="middle"
        style={{
          height: '100vh',
        }}
      >
        <Col xs={22}>
          <div
            style={{
              maxWidth: '408px',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <img
              style={{ marginBottom: 26, alignSelf: 'center' }}
              src={logo}
              width="370"
              alt="logo"
            />
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                color={colors.primaryDark}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
              >
                <Form.Item
                  name="email"
                  label={'Email'}
                  rules={[{ required: true }]}
                >
                  <Input size="large" placeholder="Enter your email" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={'Password'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: '12px' }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                <div style={{ marginBottom: '25px' }}>
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{
                        fontSize: '12px',
                        color: '#344054',
                      }}
                      onClick={() => setRememberMe(!rememberMe)}
                    >
                      {'Remember me'}
                    </Checkbox>
                  </Form.Item>
                  <a
                    style={{
                      float: 'right',
                      fontSize: '12px',
                      color: '#045DA0',
                    }}
                    onClick={navigateToForgotPassword}
                    onKeyPress={navigateToForgotPassword}
                    role="button"
                    tabIndex={0}
                  >
                    {'Forgot password?'}
                  </a>
                </div>
                <Button
                  type="default"
                  size="large"
                  htmlType="submit"
                  block
                  color={colors.primaryDark}
                  style={{
                    background: colors.authButtonColor,
                  }}
                  onClick={() => {
                    if (rememberMe) {
                      ConfigsStore.set('rememberMe', true);
                    } else {
                      sessionStorage.setItem('logoutOnEndSession', 'true');
                    }
                  }}
                >
                  <p className="auth-button-text">{'Sign in'}</p>
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
