import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { PasswordInput } from '../../components/passwordInput';

export const DashBoardAdminCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name={['email']}
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input autoComplete="on" />
        </Form.Item>

        <PasswordInput />

        <Form.Item
          label="Account Role"
          name={['role']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="Select admin role">
            <Option value="admin">Admin</Option>
            <Option value="super_admin">Super Admin</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Account Type" name={['account_type']}>
          <Select placeholder="Select account type">
            <Option value="calculation_module_admin">Suez Admin</Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
