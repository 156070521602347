import React from 'react';
import { IResourceComponentsProps, useSelect } from '@refinedev/core';
import { Edit, useForm } from '@refinedev/antd';
import dayjs from 'dayjs';
import {
  Form,
  Input,
  Select,
  InputNumber,
  Space,
  DatePicker,
  Divider,
} from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export const EarningMetricEdit: React.FC<IResourceComponentsProps> = () => {
  const {
    formProps,
    saveButtonProps,
    queryResult: earningMetricQueryResult,
  } = useForm();

  const earningMetricData = earningMetricQueryResult?.data?.data;

  const validateEndDate = (
    _: any,
    value: string | number | Date | undefined
  ) => {
    if (value && dayjs(value).isBefore(dayjs(), 'day')) {
      return Promise.reject('End date cannot be in the past');
    }

    return Promise.resolve();
  };

  const { queryResult } = useSelect({
    resource: 'product',

    filters: [
      {
        field: 'is_deleted',
        operator: 'eq',
        value: false,
      },
    ],
  });

  const transformedOptions =
    queryResult?.data?.data?.map((product) => ({
      value: product.id,
      label: `${product.type[0]?.type?.name || ''} - ${product.tier[0]?.tier?.name || ''} - ${product.segment[0]?.segment?.name || ''}`,
    })) ?? [];

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={['earning_metric_input', 'name']}
          rules={[
            {
              required: true,
              type: 'string',
              message: 'Please enter the name of the earning metric',
            },
            {
              max: 50,
              message: 'The name cannot be more than 50 characters long.',
            },
          ]}
          initialValue={earningMetricData?.name}
        >
          <Input />
        </Form.Item>

        <Divider />

        <Form.Item
          label="Type"
          name={['earning_metric_value_input', 'type']}
          rules={[
            {
              required: false,
              message: 'Please select the earning metric type',
            },
          ]}
          initialValue={earningMetricData?.earning_metric_value[0]?.type}
        >
          <Select placeholder="Select a type">
            <Option value="cashback">Cashback</Option>
            <Option value="points">Points</Option>
            <Option value="points_and_cashback">Points and Cashback</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Value"
          name={['earning_metric_value_input', 'value']}
          rules={[
            {
              required: false,
              message: 'Please enter the earning metric value',
            },
          ]}
          initialValue={earningMetricData?.earning_metric_value[0]?.value}
        >
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Enter the value for PTS per EGP"
          />
        </Form.Item>

        <Form.Item
          label="Expiry Duration (in years)"
          name={['earning_metric_value_input', 'expiry_duration']}
          rules={[
            {
              required: false,
              message: 'Please enter the expiry duration',
            },
          ]}
          initialValue={
            earningMetricData?.earning_metric_value[0]?.expiry_duration
          }
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={'MCC'}
          name={['earning_metric_value_input', 'mcc', 0]}
          rules={[
            {
              required: false,
              message: 'Please enter the merchant name',
            },
          ]}
          initialValue={
            earningMetricData?.earning_metric_value[0]
              ?.earning_metric_value_merchant[0]?.mcc
          }
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Product"
          name={['earning_metric_input', 'product_ids']}
          rules={[
            {
              required: false,
              message: 'Please enter the product details',
            },
          ]}
        >
          <Select mode="multiple" placeholder="Select products">
            {transformedOptions.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item
          label="Start Date"
          name={['earning_metric_input', 'start_date']}
          rules={[
            {
              required: false,
              message: 'Please enter the start date',
            },
          ]}
          initialValue={dayjs(earningMetricData?.start_date)}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="End Date"
          name={['earning_metric_input', 'end_date']}
          rules={[
            {
              required: false,
              message: 'Please enter the end date',
            },
            {
              validator: validateEndDate,
            },
          ]}
          initialValue={dayjs(earningMetricData?.end_date)}
        >
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Status"
          name={['earning_metric_input', 'is_deleted']}
          rules={[
            {
              required: false,
              message: 'Please select status',
            },
          ]}
          initialValue={earningMetricData?.is_deleted}
        >
          <Select style={{ width: '200px' }}>
            <Option value={false}>
              <Space>
                <CheckCircleOutlined style={{ color: 'green' }} />
                Active
              </Space>
            </Option>
            <Option value={true}>
              <Space>
                <CloseCircleOutlined style={{ color: 'red' }} />
                Inactive
              </Space>
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Edit>
  );
};
