import React from 'react';
import { IResourceComponentsProps, useShow } from '@refinedev/core';
import {
  Show,
  NumberField,
  TextField,
  DateField,
  BooleanField,
} from '@refinedev/antd';
import { Typography } from 'antd';

const { Title } = Typography;

export const DashBoardAdminShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Id</Title>
      <NumberField value={record?.account_id ?? ''} />
      <Title level={5}>Role</Title>
      <TextField value={record?.role} />
      <Title level={5}>Created At</Title>
      <DateField value={record?.created_at} />
      <Title level={5}>Is Deleted</Title>
      <BooleanField value={record?.is_deleted} />
      <Title level={5}>Name</Title>
      <TextField value={record?.account?.name} />
    </Show>
  );
};
