import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';

export const SegmentCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="segment name"
          name={['name']}
          rules={[
            {
              required: true,
            },

            {
              min: 3,
              message: 'The segment name must be at least 3 characters long.',
            },
            {
              max: 30,
              message:
                'The segment name cannot be more than 30 characters long.',
            },
            {
              type: 'string',
              whitespace: true,
            },
            {
              pattern: /^[a-zA-Z]+$/,
              message: 'Please enter a valid product segment name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
