import { Button, Modal, Upload, message } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { apiFileUpload } from '../../providers/rest-data-provider/utils/APIs';
import CloseIcon from '../../Assests/Svgs/CloseIcon';

interface Props {
  open: boolean;
  updateModalOpen: (value: boolean) => void;
  refetch: () => void;
}

const ImportUsersModal: React.FC<Props> = (props: Props) => {
  const handleCancel = () => {
    props.updateModalOpen(false);
  };
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (info: any) => {
    if (info.fileList && info.fileList[0]) {
      setFile(info.fileList[0].originFileObj);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        await apiFileUpload({
          url: 'account-cruds/add-bulks-account-suez',
          method: 'POST',
          data: formData,
        });

        message.success('File uploaded successfully', 3);
        props.updateModalOpen(false);
        setFile(null);
        props.refetch();
      } catch (error: any) {
        if (error.response?.data?.code === 'P2002') {
          message.error(
            `Duplicate entry for ${error.response.data.meta.target.join(' and ')}`,
            3
          );
        } else {
          message.error('Something went wrong', 3);
        }
        setFile(null);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={props.open}
      closeIcon={
        <button onClick={handleCancel}>
          <CloseIcon />
        </button>
      }
      centered
      width={498}
      styles={{ content: { borderRadius: '24px' } }}
      title={
        <div className="text-neutral90 text-base font-semibold">
          Import Customers Data
        </div>
      }
      footer={false}
    >
      <div className="flex flex-col items-center gap-y-6 mt-6">
        <div className="text-gray500">
          Please browse the file you want to upload
        </div>
        <div className="w-full">
          <div className="text-gray400">Upload file</div>
          <Upload
            className="flex w-full"
            onChange={handleFileChange}
            accept=".xlsx,  .xls"
            maxCount={1}
            type="select"
          >
            <Button className="upload-button w-full text-gray400">
              Browse Files
              <UploadOutlined className="upload-icon" />
            </Button>
          </Upload>
        </div>
        <Button
          size="large"
          type="primary"
          className="w-full rounded-[12px] text-white bg-elmokhtabarPrimary h-14"
          htmlType="submit"
          onClick={handleUpload}
          loading={loading}
          disabled={!file}
        >
          Upload
        </Button>
        <Button
          className="text-[#848484] hover:cursor-pointer bg-inherit border-none shadow-none"
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

export default ImportUsersModal;
