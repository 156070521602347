import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';
import { TierTypeEnum } from '../../types';

export const TierCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name={['name']}
          rules={[
            {
              required: true,
              message: 'Please enter a tier name.',
            },

            {
              min: 3,
              message: 'The tier name must be at least 3 characters long.',
            },
            {
              max: 30,
              message: 'The tier name cannot be more than 30 characters long.',
            },
            {
              type: 'string',
              whitespace: true,
            },
            {
              pattern: /^[a-zA-Z]+$/,
              message: 'Please enter a valid product tier name.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Scheme"
          name="type"
          rules={[
            {
              required: true,
              message: 'Please select a scheme.',
            },
          ]}
        >
          <Select {...TierTypeEnum}>
            {Object.values(TierTypeEnum).map((value) => (
              <Select.Option key={value} value={value}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
