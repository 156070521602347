import React from 'react';
import { useRouterContext, useRouterType, useLink } from '@refinedev/core';
import { Typography, theme, Space } from 'antd';
import type { RefineLayoutThemedTitleProps } from '../../types';
// import collapsedIcon from '../../Assests/collapsedIcon';
const { useToken } = theme;

const defaultIcon = (
  <svg
    width="180"
    height="30"
    viewBox="0 0 228 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M224.105 14.0145L225 13.1191L214.022 2.14068L213.126 3.03604L224.105 14.0145Z"
      fill="#EB6550"
    />
    <path
      d="M219.059 9.93555L218.416 10.5784V16.1693H219.682V10.5784L219.059 9.93555Z"
      fill="#EB6550"
    />
    <path
      d="M219.059 6.23378L219.682 5.59101V0H218.416V5.59101L219.059 6.23378Z"
      fill="#EB6550"
    />
    <path
      d="M224.24 1.98913L219.516 6.71387L220.411 7.60926L225.136 2.88452L224.24 1.98913Z"
      fill="#EB6550"
    />
    <path
      d="M217.69 8.5399L212.965 13.2646L213.86 14.16L218.585 9.43529L217.69 8.5399Z"
      fill="#EB6550"
    />
    <path
      d="M221.532 7.44141L220.908 8.0843L221.532 8.70769H227.142V7.44141H221.532Z"
      fill="#EB6550"
    />
    <path
      d="M216.566 7.44141H210.975V8.70769H216.566L217.208 8.0843L216.566 7.44141Z"
      fill="#EB6550"
    />
    <path d="M3.44805 0H0V29.3766H17.1818V26.1234H3.44805V0Z" fill="#282C74" />
    <path
      d="M36.643 0C32.4352 0 28.8703 1.46114 26.0651 4.36373C23.2599 7.24684 21.8184 10.8506 21.8184 15.0974C21.8184 19.3441 23.2404 22.8506 26.0261 25.7143C28.8313 28.5584 32.3573 30 36.5261 30C40.695 30 44.2988 28.539 47.104 25.6364C49.9092 22.7533 51.3508 19.1494 51.3508 14.9026C51.3508 10.6559 49.9287 7.16888 47.104 4.32473C44.3183 1.4611 40.7924 0 36.643 0ZM36.643 26.7078C33.4287 26.7078 30.7209 25.5389 28.5781 23.2597C26.4547 21 25.3833 18.1753 25.3833 14.8831C25.3833 11.5909 26.4158 8.94163 28.5391 6.66241C30.6625 4.36372 33.2729 3.25333 36.5261 3.25333C39.7794 3.25333 42.4482 4.42208 44.5911 6.70129C46.7144 8.96103 47.7859 11.7858 47.7859 15.078C47.7859 18.3702 46.7534 21.0195 44.63 23.3377C42.5066 25.6169 39.8963 26.7078 36.643 26.7078Z"
      fill="#282C74"
    />
    <path
      d="M134.104 0C129.896 0 126.331 1.46114 123.526 4.36373C120.721 7.24684 119.279 10.8506 119.279 15.0974C119.279 19.3441 120.701 22.8506 123.487 25.7143C126.292 28.5584 129.818 30 133.987 30C138.156 30 141.76 28.539 144.565 25.6364C147.37 22.7533 148.812 19.1494 148.812 14.9026C148.812 10.6559 147.39 7.16888 144.565 4.32473C141.779 1.4611 138.253 0 134.104 0ZM134.104 26.7078C130.89 26.7078 128.182 25.5389 126.039 23.2597C123.916 21 122.844 18.1753 122.844 14.8831C122.844 11.5909 123.877 8.94163 126 6.66241C128.123 4.36372 130.734 3.25333 133.987 3.25333C137.24 3.25333 139.909 4.42208 142.052 6.70129C144.175 8.96103 145.247 11.7858 145.247 15.078C145.247 18.3702 144.214 21.0195 142.091 23.3377C139.968 25.6169 137.357 26.7078 134.104 26.7078Z"
      fill="#282C74"
    />
    <path
      d="M166.382 27.3117L156.584 0.136378L156.545 0H152.727L163.675 29.2402L163.733 29.3766H169.051L180.038 0H176.298L166.382 27.3117Z"
      fill="#282C74"
    />
    <path
      d="M68.3761 13.5195L59.5125 0.0974976L59.454 0H55.3047L66.3501 16.5195L57.5449 29.3766H61.5774L81.6034 0H77.6293L68.3761 13.5195Z"
      fill="#282C74"
    />
    <path
      d="M108.136 27.1753L93.7591 0.0974976L93.7007 0H87.5254V29.3766H90.9345V2.2013L105.35 29.3766H111.545V0H108.136V27.1753Z"
      fill="#282C74"
    />
    <path
      d="M195.741 0.136378L195.702 0H190.383L179.377 29.3766H183.117L193.033 2.06493L199.072 18.7987L200.221 21.974L202.831 29.2402L202.89 29.3766H206.689L195.741 0.136378Z"
      fill="#282C74"
    />
  </svg>
);

const collapsedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="45px"
    height="43px"
    viewBox="0 0 77 73"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 60.335938 60.179688 L 64.167969 56.335938 L 17.203125 9.191406 L 13.375 13.039062 Z M 60.335938 60.179688 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 38.75 42.664062 L 36 45.425781 L 36 69.433594 L 41.417969 69.433594 L 41.417969 45.425781 Z M 38.75 42.664062 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 38.75 26.769531 L 41.417969 24.007812 L 41.417969 0 L 36 0 L 36 24.007812 Z M 38.75 26.769531 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 60.917969 8.542969 L 40.707031 28.832031 L 44.535156 32.675781 L 64.746094 12.386719 Z M 60.917969 8.542969 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 32.894531 36.671875 L 12.683594 56.960938 L 16.511719 60.804688 L 36.726562 40.515625 Z M 32.894531 36.671875 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 49.328125 31.953125 L 46.664062 34.714844 L 49.328125 37.390625 L 73.328125 37.390625 L 73.328125 31.953125 Z M 49.328125 31.953125 "
      />
      <path
        style={{
          stroke: 'none',
          fillRule: 'nonzero',
          fill: 'rgb(92.156863%,39.607843%,31.372549%)',
          fillOpacity: 1,
        }}
        d="M 28.085938 31.953125 L 4.167969 31.953125 L 4.167969 37.390625 L 28.085938 37.390625 L 30.835938 34.714844 Z M 28.085938 31.953125 "
      />
    </g>
  </svg>
);

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
  text = '',
  icon = defaultIcon,
  iconCollapsed = collapsedIcon,
  wrapperStyles,
}) => {
  const { token } = useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link;

  return (
    <ActiveLink
      to="/"
      style={{
        display: 'inline-block',
        textDecoration: 'none',
      }}
    >
      <Space
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 'inherit',
          ...wrapperStyles,
        }}
      >
        {!collapsed ? (
          <div
            style={{
              height: '24px',
              width: '24px',
              color: token.colorPrimary,
            }}
          >
            {icon}
          </div>
        ) : (
          iconCollapsed
        )}

        {!collapsed && (
          <Typography.Title
            style={{
              fontSize: 'inherit',
              marginBottom: 0,
              fontWeight: 700,
            }}
          >
            {text}
          </Typography.Title>
        )}
      </Space>
    </ActiveLink>
  );
};
