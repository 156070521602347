import React, { useState } from 'react';
import {
  BaseRecord,
  IResourceComponentsProps,
  useNavigation,
} from '@refinedev/core';
import { useTable, List } from '@refinedev/antd';
import { Button, Form, Input, Table, Upload, message } from 'antd';
import ImportIcon from '../../Assests/Svgs/ImportIcon';
import SearchIcon from '../../Assests/Svgs/SearchIcon';
import ImportUsersModal from '../../components/ImportUsers';

export const CustomersList: React.FC<IResourceComponentsProps> = () => {
  const [addCustomerModalOpen, setAddCustomerModalOpen] = useState(false);
  const {
    searchFormProps,
    tableProps,
    tableQueryResult: { refetch },
  } = useTable({
    syncWithLocation: true,
    onSearch: (value: { searchField: number }) => [
      {
        field: 'searchField',
        operator: 'eq',
        value: value.searchField,
      },
    ],
  });
  const { show } = useNavigation();

  const updateModalStatus = (value: boolean) => {
    setAddCustomerModalOpen(value);
  };

  return (
    <List
      canCreate={false}
      headerButtons={[
        <Button
          onClick={() => updateModalStatus(true)}
          className="flex text-[#344054] gap-x-1 font-bold"
        >
          <ImportIcon /> Import
        </Button>,
      ]}
    >
      <Form
        {...searchFormProps}
        layout="inline"
        className="mb-3 w-[100%] lg:w-fit"
      >
        <Form.Item
          name="searchField"
          className=" lg:w-[270px] lg:overflow-hidden"
        >
          <Input
            size="large"
            prefix={
              <SearchIcon
                className="cursor-pointer"
                onClick={() => {
                  const idValue =
                    searchFormProps?.form?.getFieldValue('searchField');
                  searchFormProps.form?.setFieldsValue({
                    searchField: idValue,
                  });
                  searchFormProps.form?.submit();
                }}
              />
            }
            placeholder="Search"
            styles={{ affixWrapper: { padding: '12px' } }}
          />
        </Form.Item>
      </Form>

      <Table
        {...tableProps}
        rowKey="id"
        onRow={(record: BaseRecord) => ({
          onClick: () => {
            show('account-cruds/financial-data', record.user_id);
          },
        })}
      >
        <Table.Column width={'55%'} dataIndex="user_name" title="Name" />
        <Table.Column width={'23%'} dataIndex="points" title="Total points" />
        <Table.Column dataIndex="cashback" title="Total cashback" />
      </Table>
      <ImportUsersModal
        open={addCustomerModalOpen}
        updateModalOpen={updateModalStatus}
        refetch={refetch}
      />
    </List>
  );
};
