import React from 'react';
import { Form, Input } from 'antd';

export const PasswordInput = () => {
  return (
    <Form.Item
      name="password"
      label={'Choose a password'}
      rules={[
        {
          required: true,
          message: 'Please enter a password',
        },

        {
          pattern:
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+-/|])[A-Za-z\d!@#$%^&*()_+-/|]/,
          message:
            'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
        },
        {
          min: 8,
          message: 'Password must be at least 8 characters',
        },
      ]}
    >
      <Input.Password type="password" size="large" placeholder="••••••••" />
    </Form.Item>
  );
};
