import React, { useState } from 'react';
import { BaseRecord, IResourceComponentsProps } from '@refinedev/core';
import { List, useTable } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Table } from 'antd';
import AwaitsCashBackPointsCustomerModal from '../../components/Modals/AwaitsCashBackPointsCustomerModal';
import SecurityQuestionModal from '../../components/Modals/SecurityQuestionModal';
// import { CustomerDetails } from '../../types';
import SearchIcon from '../../Assests/Svgs/SearchIcon';
import { Transaction } from '../../types';
import dayjs from 'dayjs';
import { SyncOutlined } from '@ant-design/icons';

interface CustomerDetails extends BaseRecord {
  name: string;
  Transactions: Transaction[];
}

export const CustomersShow: React.FC<IResourceComponentsProps> = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [securityQuestionModal, setSecurityQuestionModal] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [amountAdded, setAmountAdded] = useState<{
    amount: number;
    user_id: number;
    is_cashback: boolean;
  }>({
    amount: 0,
    user_id: 0,
    is_cashback: false,
  });
  const [select, setSelect] = useState('pts');

  const handleModalOpen = (value: boolean) => {
    setSecurityQuestionModal(value);
  };
  const { id } = useParams<{ id: string }>();
  const {
    tableProps,
    searchFormProps,
    tableQueryResult: { refetch },
    setFilters,
  } = useTable({
    resource: `rewarding-transactions/customer-details/${id}`,
    onSearch: (value: {
      searchField: any;
      fromDate: string;
      toDate: string;
    }) => [
      {
        field: 'searchField',
        operator: 'eq',
        value: value.searchField,
      },
      { field: 'fromDate', operator: 'eq', value: value.fromDate },
      { field: 'toDate', operator: 'eq', value: value.toDate },
    ],
  });
  const formatDate = (inputDate: string) => {
    const day = dayjs(inputDate).format('DD');
    const month = dayjs(inputDate).format('MM'); // Months are zero-based
    const year = dayjs(inputDate).format('YYYY');

    return `${day}/${month}/${year}`;
  };
  const customerDetails = tableProps?.dataSource as CustomerDetails | undefined;

  const handleReset = () => {
    searchFormProps.form?.resetFields();
    //@ts-ignore
    setFilters((currentFilters) => {
      return currentFilters.filter(
        (filter: { field: string }) =>
          filter.field === 'business_health_form_id'
      );
    }, 'replace');
    refetch();
  };
  const handleDateChange = (
    field: string,
    value: string | number | Date | dayjs.Dayjs | null | undefined
  ) => {
    // eslint-disable-next-line no-console
    console.log(field, value);
    // searchFormProps.form?.setFieldsValue({
    //   [field]: value ? dayjs(value).toISOString().slice(0, 10) : null,
    // });
    searchFormProps.form?.submit();
  };
  return (
    <div className="relative">
      <div className={securityQuestionModal ? 'blur-sm' : ''}>
        <List canCreate={false}>
          <div className="flex justify-between pb-6">
            <div className="flex gap-5 items-center">
              <img
                src="/src/Assests/sideIcons/userIcon.svg"
                className=" bg-slate-200  p-3 rounded-full"
                alt=""
              />
              <p className="text-3xl font-semibold">
                {customerDetails ? customerDetails.name : ''}
              </p>
            </div>
            <Button
              className=" h-fit py-3  px-3 text-sm text-white rounded-lg font-semibold bg-[#282C74]"
              key=""
              onClick={() => {
                setAmountAdded({ ...amountAdded, user_id: id ? +id : 0 });
                setModalOpen(true);
              }}
            >
              Award points/cashback
            </Button>
          </div>
          <Form
            {...searchFormProps}
            className="  p-2 items-center rounded-lg justify-between flex"
            onFieldsChange={() => {
              searchFormProps.form?.submit();
            }}
          >
            <Form.Item
              name="searchField"
              className=" lg:w-[270px] lg:overflow-hidden"
            >
              <Input
                size="large"
                prefix={
                  <SearchIcon
                    className="cursor-pointer"
                    onClick={() => {
                      const idValue =
                        searchFormProps?.form?.getFieldValue('searchField');
                      searchFormProps.form?.setFieldsValue({
                        searchField: idValue,
                      });
                      searchFormProps.form?.submit();
                    }}
                  />
                }
                placeholder="Search"
                styles={{ affixWrapper: { padding: '12px' } }}
              />
            </Form.Item>

            <div className="flex justify-between gap-x-3">
              <Form.Item className="w-[48%]" name="fromDate">
                <DatePicker
                  format="MM/DD/YYYY"
                  placeholder="From Date"
                  name="fromDate"
                  onChange={(value) => handleDateChange('fromDate', value)}
                />
              </Form.Item>
              <Form.Item className="w-[48%]" name="toDate">
                <DatePicker
                  format="MM/DD/YYYY"
                  placeholder="To Date"
                  name="toDate"
                  onChange={(value) => handleDateChange('toDate', value)}
                />
              </Form.Item>
              <Button
                onClick={() => handleReset()}
                type="primary"
                icon={<SyncOutlined />}
              />
            </div>
          </Form>
          <AwaitsCashBackPointsCustomerModal
            visible={modalOpen}
            onClose={() => {
              setModalOpen(false);
              setAmountAdded({
                ...amountAdded,
                amount: 0,
                user_id: id ? +id : 0,
              });
              setConfirm(false);
            }}
            confirm={confirm}
            setConfirm={setConfirm}
            amountAdded={amountAdded}
            setAmountAdded={setAmountAdded}
            select={select}
            setSelect={setSelect}
          />
          <Table
            dataSource={
              customerDetails?.Transactions ? customerDetails?.Transactions : []
            }
            loading={tableProps.loading}
            rowKey="id"
          >
            <Table.Column dataIndex="id" title="Transaction ID" />
            <Table.Column
              dataIndex="amount"
              title="Amount"
              render={(value) => <p>{value ? value : 0}</p>}
            />
            <Table.Column
              dataIndex="points"
              title="Points received"
              render={(value) => <p>{value ? value : 0}</p>}
            />
            <Table.Column
              dataIndex="cashback_credit"
              title="Cashback received"
              render={(value) => <p>{value ? value : 0}</p>}
            />
            <Table.Column
              dataIndex="trx_date"
              title="Date"
              render={(value) => <p>{value ? formatDate(value) : ''}</p>}
            />
          </Table>
        </List>
      </div>
      <SecurityQuestionModal
        visible={securityQuestionModal}
        onClose={handleModalOpen}
        id={id}
        handleModalOpen={handleModalOpen}
      />
    </div>
  );
};
