import * as React from 'react';
import { SVGProps } from 'react';
const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#424242"
      fillRule="evenodd"
      d="M5.151 5.152a1.2 1.2 0 0 1 1.697 0L12 10.303l5.151-5.151a1.2 1.2 0 1 1 1.697 1.697L13.697 12l5.151 5.151a1.2 1.2 0 1 1-1.697 1.698L12 13.697 6.848 18.85a1.2 1.2 0 0 1-1.697-1.698L10.303 12 5.15 6.849a1.2 1.2 0 0 1 0-1.697Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CloseIcon;
