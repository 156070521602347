import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { Edit } from '../../components/crud/edit';
import { useFormik } from 'formik';
import { EditRecordAction } from '../../Actions/ConfigsActions';

export const AdminEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm({
    resource: 'admin',
  });

  if (queryResult?.data?.data) {
    if (queryResult?.data?.data?.account) {
      queryResult.data.data = queryResult?.data?.data?.account;
    }
  }

  const { handleSubmit, handleChange } = useFormik({
    initialValues: {
      id: queryResult?.data?.data?.id,
      first_name: queryResult?.data?.data?.first_name,
      last_name: queryResult?.data?.data?.last_name,
      email: queryResult?.data?.data?.email,
      cognito_username: queryResult?.data?.data?.cognito_username,
    },
    onSubmit: async (submittedValues) => {
      const inputs: any = {
        id: queryResult?.data?.data?.id,
        first_name: submittedValues.first_name,
        last_name: submittedValues.last_name,
        email: submittedValues.email,
        cognito_username: submittedValues.cognito_username,
      };

      if (Object.keys(inputs)?.length > 0) {
        EditRecordAction('account', queryResult?.data?.data?.id, inputs);
      }
    },
  });

  const buttonProps = {
    disables: saveButtonProps?.loading,
    loading: saveButtonProps?.loading,
    onClick: () => handleSubmit(),
  };

  return (
    <Edit saveButtonProps={buttonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="First Name"
          name={['first_name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="first_name" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name={['last_name']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="last_name" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Email"
          name={['email']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="email" onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label="Cognito Username"
          name={['cognito_username']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input name="cognito_username" onChange={handleChange} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
